import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';
import { PDFRenderer } from './pdfRenderer';
import { getBaseUrl } from '../config/ApiConfig';

export class SecureViewer {
  static createSecureFrame(content, title = 'Secure View') {
    const viewId = uuidv4();
    const token = localStorage.getItem('authToken');
    
    // Pre-process image URLs to use data-auth-src
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;
    tempDiv.querySelectorAll('img').forEach(img => {
      const src = img.getAttribute('src');
      if (src) {
        // Convert file path to endpoint URL if needed
        const isFilePath = src.startsWith('file://') || src.startsWith('/home/');
        if (isFilePath) {
          // Extract the relevant parts from the file path
          const parts = src.split('/');
          const chartIndex = parts.indexOf('charts');
          if (chartIndex !== -1) {
            const chartPath = parts.slice(chartIndex + 1).join('/');
            img.setAttribute('data-auth-src', `/user-charts/${chartPath}`);
            img.removeAttribute('src');
          }
        } else if (src.includes('/user-charts/')) {
          img.setAttribute('data-auth-src', src);
          img.removeAttribute('src');
        }
      }
    });
    content = tempDiv.innerHTML;

    // Sanitize content
    const sanitizedContent = DOMPurify.sanitize(content, {
      FORBID_TAGS: ['iframe', 'object', 'embed'],
      FORBID_ATTR: ['onerror', 'onclick'],
      ADD_TAGS: ['script', 'img'],
      ADD_ATTR: ['data-auth-src', 'src']
    });

    // Post-process sanitized content to handle image authentication
    const postDiv = document.createElement('div');
    postDiv.innerHTML = sanitizedContent;
    postDiv.querySelectorAll('img').forEach(img => {
      const src = img.getAttribute('data-auth-src');
      if (src && src.includes('/user-charts/')) {
        // Store original src for reference
        img.setAttribute('data-original-src', src);
        // Create a blob URL that will make an authenticated request
        fetch(`${src}${src.includes('?') ? '&' : '?'}token=${token}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);
          img.setAttribute('src', blobUrl);
        })
        .catch(console.error);
      }
    });
    content = postDiv.innerHTML;

    // 1. Create container
    const container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.width = '100vw';
    container.style.height = '100vh';
    container.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
    container.style.zIndex = '9999';
    container.style.display = 'flex';
    container.style.flexDirection = 'column';
    container.style.alignItems = 'center';

    // 2. Create frame with sandbox attribute
    const frame = document.createElement('iframe');
    frame.sandbox = 'allow-same-origin allow-scripts allow-popups allow-downloads';
    frame.style.width = '98vw';
    frame.style.maxWidth = '1200px';
    frame.style.height = 'calc(98vh - 40px)';
    frame.style.margin = '20px auto';
    frame.style.display = 'block';
    frame.style.backgroundColor = 'white';
    frame.style.border = 'none';
    frame.style.borderRadius = '8px';

    // 3. Create close button
    const closeButton = document.createElement('button');
    closeButton.innerText = '✕';
    closeButton.style.position = 'fixed';
    closeButton.style.top = '10px';
    closeButton.style.right = '10px';
    closeButton.style.padding = '8px 16px';
    closeButton.style.backgroundColor = '#dc3545';
    closeButton.style.color = 'white';
    closeButton.style.border = 'none';
    closeButton.style.borderRadius = '4px';
    closeButton.style.cursor = 'pointer';
    closeButton.style.zIndex = '10000';
    closeButton.style.fontSize = '1.2rem';
    closeButton.style.fontWeight = 'bold';
    closeButton.style.transition = 'background-color 0.2s';

    // Create export PDF button
    const exportButton = document.createElement('button');
    exportButton.innerText = '📥 PDF';
    exportButton.style.position = 'fixed';
    exportButton.style.top = '10px';
    exportButton.style.right = '70px';
    exportButton.style.padding = '8px 16px';
    exportButton.style.backgroundColor = '#28a745';
    exportButton.style.color = 'white';
    exportButton.style.border = 'none';
    exportButton.style.borderRadius = '4px';
    exportButton.style.cursor = 'pointer';
    exportButton.style.zIndex = '10000';
    exportButton.style.fontSize = '1.2rem';
    exportButton.style.fontWeight = 'bold';
    exportButton.style.transition = 'background-color 0.2s';

    // 4. Prepare content
    const htmlContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${title}</title>
          <meta http-equiv="Content-Security-Policy" 
                content="default-src 'self'; 
                         style-src 'unsafe-inline' 'self' https://cdn.jsdelivr.net https://fonts.googleapis.com;
                         script-src 'unsafe-inline' 'self' https://cdn.jsdelivr.net;
                         font-src 'self' https://cdn.jsdelivr.net https://fonts.gstatic.com;
                         img-src 'self' data: blob: ${window.location.origin} https: http:;
                         connect-src *;">
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Fira+Code:wght@400;500&display=swap" rel="stylesheet">
          
          <!-- MathJax configuration -->
          <script>
            window.MathJax = { 
              tex: { 
                inlineMath: [['$', '$'], ['\\\\(', '\\\\)']], 
                displayMath: [['$$', '$$'], ['\\\\[', '\\\\]']], 
                processEscapes: true 
              }, 
              svg: { 
                fontCache: 'global', 
                scale: 1.0, 
                linebreaks: { automatic: true } 
              } 
            };
          </script>
          
          <!-- Image authentication script -->
          <script>
            document.addEventListener('DOMContentLoaded', () => {
              const authToken = '${token}';
              document.querySelectorAll('img').forEach(img => {
                const originalSrc = img.getAttribute('data-original-src');
                if (originalSrc && originalSrc.includes('/user-charts/')) {
                  img.src = originalSrc + (originalSrc.includes('?') ? '&' : '?') + 'token=' + authToken;
                }
              });
            });
          </script>$', '$'], ['\\\\(', '\\\\)']],
                displayMath: [['$$', '$$'], ['\\\\[', '\\\\]']],
                processEscapes: true,
                processEnvironments: true,
                tagSide: 'right',
                tagIndent: '.8em',
                multlineWidth: '85%',
                tags: 'ams'
              },
              options: {
                skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre']
              },
              svg: {
                fontCache: 'global',
                scale: 1.0,
                displayAlign: 'left',
                displayIndent: '0',
                linebreaks: { automatic: true, width: "container" }
              },
              startup: {
                ready: () => {
                  MathJax.startup.defaultReady();
                  
                  // After MathJax finishes rendering, fix layout and store original LaTeX
                  MathJax.typesetPromise().then(() => {
                    // Store original LaTeX in data attributes
                    document.querySelectorAll('mjx-container').forEach(el => {
                      // Ensure all elements are visible with correct layout
                      el.style.display = el.getAttribute('display') === 'true' ? 'block' : 'inline-block';
                      el.style.overflow = 'visible';
                      el.style.maxWidth = '100%';
                      
                      // Add word-wrap to force line breaks for long expressions
                      const math = el.querySelector('mjx-math');
                      if (math) {
                        math.style.wordWrap = 'break-word';
                        math.style.whiteSpace = 'normal';
                        math.style.maxWidth = '100%';
                      }
                    });
                  });
                }
              }
            };
          </script>
          <script src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js"></script>
          <script src="https://cdn.jsdelivr.net/npm/html2pdf.js/dist/html2pdf.bundle.min.js"></script>
          <script>
                  });
                }
              }$', '$'], ['\\\\(', '\\\\)']],
                displayMath: [['$$', '$$'], ['\\\\[', '\\\\]']],
                processEscapes: true,
                processEnvironments: true,
                tagSide: 'right',
                tagIndent: '.8em',
                multlineWidth: '85%',
                tags: 'ams',
                // Force LaTeX to wrap lines similar to PDF output
                maxMathBits: 1000,
                maxBuffer: 5000
              },
              options: {
                skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre'],
                renderActions: {
                  addMenu: [], // Disable the menu because it causes problems in our container
                  checkLoading: []
                }
              },
              svg: {
                fontCache: 'global',
                scale: 1.0,
                displayAlign: 'left',
                displayIndent: '0',
                linebreaks: { automatic: true, width: "container" }
              },
              chtml: {
                scale: 1.0,
                displayAlign: 'left',
                displayIndent: '0',
                mtextInheritFont: true,
                matchFontHeight: true,
                linebreaks: { automatic: true, width: "container" }
              },
              output: {
                font: 'mathjax-modern',
                scale: 1.0,
                displayOverflow: 'linebreak',
                linebreaks: { automatic: true, width: "container" }
              },
              startup: {
                ready: () => {
                  MathJax.startup.defaultReady();
                  
                  // After MathJax finishes rendering, store original LaTeX in data attributes
                  MathJax.typesetPromise().then(() => {
                    document.querySelectorAll('.MathJax').forEach(el => {
                      const originalMath = el.getAttribute('data-original') || el.getAttribute('data-latex');
                      if (originalMath) {
                        el.setAttribute('data-latex', originalMath);
                      }
                    });
                    
                    // Ensure all MathJax elements are visible and properly sized
                    document.querySelectorAll('mjx-container').forEach(el => {
                      el.style.display = el.getAttribute('display') === 'true' ? 'block' : 'inline-block';
                      el.style.overflow = 'visible';
                    });
                  });
                }
              }
            };
          </script>
          <script>$', '$'], ['\\(', '\\)']],
                displayMath: [['$$', '$$'], ['\\[', '\\]']],
                processEscapes: true,
                processEnvironments: true
              },
              options: {
                skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'pre']
              },
              svg: {
                fontCache: 'global',
                scale: 1.0,
                displayAlign: 'left',
                displayIndent: '0'
              },
              chtml: {
                scale: 1.0,
                displayAlign: 'left',
                displayIndent: '0',
                mtextInheritFont: true,
                matchFontHeight: true
              },
              output: {
                font: 'mathjax-modern',
                displayOverflow: 'linebreak'
              },
              startup: {
                ready: () => {
                  MathJax.startup.defaultReady();
                  
                  // After MathJax finishes rendering, store original LaTeX in data attributes
                  MathJax.typesetPromise().then(() => {
                    document.querySelectorAll('.MathJax').forEach(el => {
                      const originalMath = el.getAttribute('data-original') || el.getAttribute('data-latex');
                      if (originalMath) {
                        el.setAttribute('data-latex', originalMath);
                      }
                    });
                    
                    // Ensure all MathJax elements are visible and properly sized
                    document.querySelectorAll('mjx-container').forEach(el => {
                      el.style.display = el.getAttribute('display') === 'true' ? 'block' : 'inline-block';
                      el.style.overflow = 'visible';
                    });
                  });
                }
              }
            };
          </script>
          <script>
            // Add copy event handler to preserve LaTeX
            document.addEventListener('copy', (e) => {
              const selection = window.getSelection();
              if (!selection.rangeCount) return;
              
              const container = document.createElement('div');
              const range = selection.getRangeAt(0);
              container.appendChild(range.cloneContents());
              
              // Replace MathJax elements with their original LaTeX
              container.querySelectorAll('.MathJax').forEach(el => {
                const latex = el.getAttribute('data-latex');
                if (latex) {
                  const textNode = document.createTextNode(latex);
                  el.parentNode.replaceChild(textNode, el);
                }
              });
              
              e.clipboardData.setData('text/plain', container.innerText);
              e.clipboardData.setData('text/html', container.innerHTML);
              e.preventDefault();
            });
          </script>
          <style>
            /* Base styles - matching PDF output */
            body { 
              margin: 0;
              padding: 0;
              background-color: #ffffff;
              font-family: 'Inter', Arial, sans-serif;
              font-size: 14px;
              line-height: 1.5;
              color: #24292e;
              overflow-y: auto;
              overflow-x: hidden;
              display: flex;
              justify-content: center;
              min-height: 100vh;
              box-sizing: border-box;
            }
            
            /* Content container - wider than PDF but narrower than original */
            .content-wrapper {
              max-width: 700px; /* Halfway between original 800px and PDF 555px */
              margin: 0 auto;
              padding: 20mm;
              box-sizing: border-box;
            }
            
            /* Heading styles matching PDF */
            h1, h2, h3, h4, h5, h6 {
              margin-top: 1em;
              margin-bottom: 0.5em;
              page-break-after: avoid;
              font-family: Arial, sans-serif;
            }
            
            /* Paragraph styles matching PDF */
            p {
              margin: 0.5em 0;
              orphans: 3;
              widows: 3;
              line-height: 1.5;
              text-align: left;
            }
            
            /* Block elements matching PDF */
            pre, table, figure {
              page-break-inside: avoid;
              margin: 1em 0;
            }
            
            /* Code block styles matching PDF */
            pre {
              background-color: #f6f8fa;
              border-radius: 6px;
              padding: 1em;
              border: 1px solid #e1e4e8;
              overflow-x: auto;
            }
            
            code {
              font-family: 'Fira Code', 'Courier New', Courier, monospace;
              background-color: #f6f8fa;
              color: #24292e;
              padding: 0.2em 0.4em;
              border-radius: 3px;
              font-size: 85%;
            }
            
            /* List styles matching PDF */
            ul, ol {
              margin: 0.5em 0;
              padding-left: 2em;
            }
            
            li {
              margin-bottom: 0.3em;
            }
            
            /* Image styles matching PDF */
            img {
              max-width: 100%;
              page-break-inside: avoid;
              display: block;
              margin: 1em auto;
            }
            
            /* Special handling for content with LaTeX - match PDF wrapping */
            mjx-container {
              display: inline-block !important;
              overflow: visible !important;
              max-width: 100% !important;
              white-space: normal !important;
              word-break: normal !important;
              text-align: left !important;
            }
            
            /* Specific handling for display math in LaTeX */
            mjx-container[display="true"] {
              display: block !important;
              margin: 1em auto !important;
              max-width: 100% !important;
              overflow: visible !important;
              text-align: center !important;
            }
            
            /* Force long LaTeX to wrap */
            mjx-math {
              max-width: 100% !important;
            }
            
            /* Additional LaTeX tweaks */
            mjx-container svg {
              max-width: 100% !important;
              overflow: visible !important;
            }
            
            /* Image/chart container */
            .image-container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              margin: 1vh 0;
            }
            
            /* Chart specific */
            img[data-auth-src] {
              width: 95%;
              height: auto;
              max-height: 90vh;
              object-fit: contain;
              display: block;
              margin: 0 auto;
            }
            
            /* Thumbnail styles */
            .image-thumbnail {
              width: 90%;
              max-width: 600px;
              margin: 2vh auto;
              cursor: default;
              border-radius: 4px;
              transition: all 0.2s ease-out;
              position: relative;
              display: block;
            }
            .image-thumbnail:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba(0,0,0,0.1);
            }
            .image-thumbnail:focus {
              outline: 3px solid #007bff;
              outline-offset: 2px;
            }
            
            /* Loading indicator */
            .loading-spinner {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 40px;
              height: 40px;
              border: 3px solid rgba(0,0,0,0.1);
              border-radius: 50%;
              border-top-color: #007bff;
              animation: spin 1s linear infinite;
            }
            @keyframes spin {
              to { transform: translate(-50%, -50%) rotate(360deg); }
            }
            
            /* Overlay styles */
            .image-overlay {
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: rgba(0, 0, 0, 0.95);
              z-index: 1000;
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease-out;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .image-overlay.active {
              opacity: 1;
              visibility: visible;
            }
            
            /* Fullsize image */
            .fullsize-image {
              max-width: 100%;
              max-height: 100%;
              object-fit: contain;
              transform-origin: center;
              cursor: grab;
              transition: transform 0.2s ease-out;
              will-change: transform;
            }
            .fullsize-image.grabbing {
              cursor: grabbing;
            }
            
            /* Controls */
            .overlay-controls {
              position: fixed;
              bottom: 20px;
              left: 50%;
              transform: translateX(-50%);
              display: flex;
              gap: 20px;
              background: rgba(0, 0, 0, 0.7);
              padding: 12px 20px;
              border-radius: 8px;
              z-index: 1001;
              opacity: 0.7;
              transition: opacity 0.2s;
            }
            .overlay-controls:hover {
              opacity: 1;
            }
            
            .control-button {
              background: none;
              border: none;
              color: white;
              font-size: 20px;
              cursor: pointer;
              padding: 8px;
              border-radius: 4px;
              transition: background-color 0.2s;
            }
            .control-button:hover {
              background: rgba(255,255,255,0.1);
            }
            .control-button:focus {
              outline: 2px solid #007bff;
              outline-offset: 2px;
            }
            
            /* Zoom info */
            .zoom-info {
              position: fixed;
              top: 20px;
              left: 50%;
              transform: translateX(-50%);
              background: rgba(0, 0, 0, 0.7);
              color: white;
              padding: 8px 16px;
              border-radius: 4px;
              font-size: 14px;
              opacity: 0;
              transition: opacity 0.3s;
              z-index: 1001;
            }
            .zoom-info.visible {
              opacity: 1;
            }
            
            /* Error state */
            .error-state {
              border: 2px solid #dc3545;
              padding: 10px;
              border-radius: 4px;
              color: #dc3545;
              background: rgba(220, 53, 69, 0.1);
              margin: 10px 0;
              font-size: 14px;
            }
            
            /* Enhanced styling for MathJax */
            .MathJax {
              overflow-x: auto;
              max-width: 100%;
              display: inline-block !important;
              margin: 0 !important;
              vertical-align: middle !important;
              white-space: normal !important;
            }
            
            /* Ensure formulas can be scrolled if needed */
            mjx-container {
              overflow-x: auto;
              max-width: 100%;
              display: inline-block !important;
              text-align: left !important;
              white-space: normal !important;
              font-size: 100% !important;
            }
            
            /* Fix for overlapping formula containers */
            mjx-container[jax="CHTML"][display="true"] {
              display: block !important;
              margin: 1em 0 !important;
              text-align: center !important;
            }
            
            /* Fix for LaTeX display formulas */
            mjx-container[display="true"] mjx-math {
              max-width: 100% !important;
              overflow-x: auto !important;
              overflow-y: hidden !important;
              padding: 5px 0 !important;
            }
          </style>
        </head>
        <body>
          ${sanitizedContent}
          <script>
            (function() {
              const authImages = document.querySelectorAll('img[data-auth-src]');
              const token = '${token}';
              const apiBaseUrl = '${getBaseUrl()}/'; // Update apiBaseUrl to use the correct environment URL from getBaseUrl()
              
              // Create content wrapper to match PDF layout
              let contentWrapper = document.querySelector('.content-wrapper');
              if (!contentWrapper) {
                contentWrapper = document.createElement('div');
                contentWrapper.className = 'content-wrapper';
                document.body.appendChild(contentWrapper);
                
                // Move content to wrapper
                while (document.body.firstChild !== contentWrapper) {
                  const child = document.body.firstChild;
                  if (child !== contentWrapper) {
                    contentWrapper.appendChild(child);
                  }
                }
              }
              
              // Fix LaTeX rendering after MathJax is loaded
              function fixMathJaxRendering() {
                if (window.MathJax && window.MathJax.typesetPromise) {
                  window.MathJax.typesetPromise().then(() => {
                    // Fix each mjx-container element
                    document.querySelectorAll('mjx-container').forEach(el => {
                      el.style.display = el.getAttribute('display') === 'true' ? 'block' : 'inline-block';
                      el.style.overflow = 'visible';
                      el.style.maxWidth = '100%';
                      
                      const svg = el.querySelector('svg');
                      if (svg) {
                        svg.style.maxWidth = '100%';
                        svg.style.overflow = 'visible';
                      }
                    });
                  });
                } else {
                  setTimeout(fixMathJaxRendering, 200);
                }
              }
              
              // Start fixing LaTeX after a short delay
              setTimeout(fixMathJaxRendering, 500);
              
              authImages.forEach(img => {
                // Setup image structure
                img.className = 'image-thumbnail';
                img.setAttribute('tabindex', '0');
                img.setAttribute('role', 'button');
                img.setAttribute('aria-label', 'Click to view full size');
                
                // Create loading spinner
                const spinner = document.createElement('div');
                spinner.className = 'loading-spinner';
                img.parentNode.appendChild(spinner);
                
                // Create overlay structure
                const overlay = document.createElement('div');
                overlay.className = 'image-overlay';
                overlay.setAttribute('role', 'dialog');
                overlay.setAttribute('aria-label', 'Image viewer');
                
                const imageContainer = document.createElement('div');
                imageContainer.className = 'image-container';
                
                const fullsizeImage = document.createElement('img');
                fullsizeImage.className = 'fullsize-image';
                fullsizeImage.setAttribute('alt', img.getAttribute('alt') || '');
                
                // Create controls
                const controls = document.createElement('div');
                controls.className = 'overlay-controls';
                
                const zoomOutBtn = document.createElement('button');
                zoomOutBtn.className = 'control-button';
                zoomOutBtn.innerHTML = '&#8722;';
                zoomOutBtn.setAttribute('aria-label', 'Zoom out');
                
                const resetBtn = document.createElement('button');
                resetBtn.className = 'control-button';
                resetBtn.innerHTML = '&#8634;';
                resetBtn.setAttribute('aria-label', 'Reset zoom');
                
                const zoomInBtn = document.createElement('button');
                zoomInBtn.className = 'control-button';
                zoomInBtn.innerHTML = '&#43;';
                zoomInBtn.setAttribute('aria-label', 'Zoom in');
                
                const closeBtn = document.createElement('button');
                closeBtn.className = 'control-button';
                closeBtn.innerHTML = '&#10005;';
                closeBtn.setAttribute('aria-label', 'Close viewer');
                
                controls.append(zoomOutBtn, resetBtn, zoomInBtn, closeBtn);
                
                // Create zoom info
                const zoomInfo = document.createElement('div');
                zoomInfo.className = 'zoom-info';
                
                // Build overlay structure
                imageContainer.appendChild(fullsizeImage);
                overlay.appendChild(imageContainer);
                overlay.appendChild(controls);
                overlay.appendChild(zoomInfo);
                document.body.appendChild(overlay);
                
                // Initialize state
                let scale = 1;
                let translateX = 0;
                let translateY = 0;
                let isDragging = false;
                let startX = 0;
                let startY = 0;
                
                function updateTransform() {
                  requestAnimationFrame(() => {
                    const matrix = new DOMMatrix()
                      .translate(overlay.clientWidth / 2, overlay.clientHeight / 2)
                      .translate(translateX, translateY)
                      .scale(scale)
                      .translate(-overlay.clientWidth / 2, -overlay.clientHeight / 2);
                    
                    fullsizeImage.style.transform = matrix.toString();
                  });
                }
                
                function constrainPosition() {
                  const bounds = fullsizeImage.getBoundingClientRect();
                  const containerBounds = overlay.getBoundingClientRect();
                  
                  const maxX = Math.max(0, (bounds.width * scale - containerBounds.width) / 2);
                  const maxY = Math.max(0, (bounds.height * scale - containerBounds.height) / 2);
                  
                  translateX = Math.max(-maxX, Math.min(maxX, translateX));
                  translateY = Math.max(-maxY, Math.min(maxY, translateY));
                }
                
                function setScale(newScale, centerX, centerY) {
                  const oldScale = scale;
                  scale = Math.min(Math.max(1, newScale), 4); // Limit zoom between 1x and 4x
                  
                  if (scale !== oldScale) {
                    // Adjust position to maintain zoom center
                    const scaleRatio = scale / oldScale;
                    const rect = fullsizeImage.getBoundingClientRect();
                    const imgX = centerX - rect.left;
                    const imgY = centerY - rect.top;
                    
                    translateX = (translateX - imgX) * scaleRatio + imgX;
                    translateY = (translateY - imgY) * scaleRatio + imgY;
                    
                    constrainPosition();
                    updateTransform();
                  }
                }
                
                // Zoom handler
                overlay.addEventListener('wheel', (e) => {
                  e.preventDefault();
                  
                  const delta = -Math.sign(e.deltaY) * 0.1;
                  const rect = fullsizeImage.getBoundingClientRect();
                  const newScale = scale * (1 + delta);
                  
                  setScale(newScale, e.clientX, e.clientY);
                }, { passive: false });
                
                // Preserve existing drag handlers
                overlay.addEventListener('mousedown', (e) => {
                  if (e.button !== 0) return;
                  e.preventDefault();
                  isDragging = true;
                  startX = e.clientX - translateX;
                  startY = e.clientY - translateY;
                  fullsizeImage.style.cursor = 'grabbing';
                });
                
                window.addEventListener('mousemove', (e) => {
                  if (!isDragging) return;
                  translateX = e.clientX - startX;
                  translateY = e.clientY - startY;
                  constrainPosition();
                  updateTransform();
                });
                
                window.addEventListener('mouseup', () => {
                  isDragging = false;
                  fullsizeImage.style.cursor = 'grab';
                });
                
                // Touch zoom and drag
                let lastTouchDistance = 0;
                
                overlay.addEventListener('touchstart', (e) => {
                  if (e.touches.length === 1) {
                    e.preventDefault();
                    isDragging = true;
                    startX = e.touches[0].clientX - translateX;
                    startY = e.touches[0].clientY - translateY;
                  } else if (e.touches.length === 2) {
                    lastTouchDistance = Math.hypot(
                      e.touches[0].clientX - e.touches[1].clientX,
                      e.touches[0].clientY - e.touches[1].clientY
                    );
                  }
                }, { passive: false });
                
                overlay.addEventListener('touchmove', (e) => {
                  e.preventDefault();
                  if (e.touches.length === 1 && isDragging) {
                    translateX = e.touches[0].clientX - startX;
                    translateY = e.touches[0].clientY - startY;
                    constrainPosition();
                    updateTransform();
                  } else if (e.touches.length === 2) {
                    const distance = Math.hypot(
                      e.touches[0].clientX - e.touches[1].clientX,
                      e.touches[0].clientY - e.touches[1].clientY
                    );
                    
                    if (lastTouchDistance > 0) {
                      const centerX = (e.touches[0].clientX + e.touches[1].clientX) / 2;
                      const centerY = (e.touches[0].clientY + e.touches[1].clientY) / 2;
                      const newScale = scale * (distance / lastTouchDistance);
                      setScale(newScale, centerX, centerY);
                    }
                    
                    lastTouchDistance = distance;
                  }
                }, { passive: false });
                
                overlay.addEventListener('touchend', () => {
                  isDragging = false;
                  lastTouchDistance = 0;
                });
                
                // Load image
                const authSrc = img.getAttribute('data-auth-src');
                const fullUrl = apiBaseUrl + authSrc;
                
                fetch(fullUrl, {
                  headers: {
                    'Authorization': 'Bearer ' + token
                  },
                  mode: 'cors'
                })
                .then(response => {
                  if (!response.ok) throw new Error(response.status === 404 ? 'Image not found' : 'Failed to load image');
                  return response.blob();
                })
                .then(blob => {
                  const objectUrl = URL.createObjectURL(blob);
                  img.onload = () => {
                    spinner.remove();
                    img.classList.remove('image-loading');
                    fullsizeImage.src = img.src;
                    URL.revokeObjectURL(img.src);
                  };
                  img.src = objectUrl;
                })
                .catch(error => {
                  console.error('Error loading image:', error);
                  spinner.remove();
                  const errorDiv = document.createElement('div');
                  errorDiv.className = 'error-state';
                  errorDiv.textContent = 'Failed to load image: ' + error.message;
                  img.parentNode.insertBefore(errorDiv, img.nextSibling);
                });
              });
            })();
          </script>
        </body>
      </html>
    `;

    // 5. Set up event handlers
    const handleClose = () => {
      document.removeEventListener('keydown', handleKeyDown);
      container.remove();
      this.logViewerClose(viewId);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    closeButton.onclick = handleClose;
    closeButton.onmouseover = () => closeButton.style.backgroundColor = '#c82333';
    closeButton.onmouseout = () => closeButton.style.backgroundColor = '#dc3545';

    exportButton.onmouseover = () => exportButton.style.backgroundColor = '#218838';
    exportButton.onmouseout = () => exportButton.style.backgroundColor = '#28a745';

    exportButton.onclick = () => {
      // Prompt for filename
      const defaultName = title.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const filename = prompt('Enter filename for PDF:', defaultName);
      
      if (!filename) return; // User cancelled
      
      // Always use LaTeX support in PDF exports to ensure consistent rendering
      const isLatex = true;
      
      // Pass the original sanitized content to PDFRenderer with latex parameter
      PDFRenderer.generatePDF(sanitizedContent, filename, isLatex).catch(error => {
        console.error('PDF generation failed:', error);
        alert('Failed to generate PDF. Please try again.');
      });
    };

    // 6. Set up content writer
    const writeContent = () => {
      try {
        const secureDoc = frame.contentDocument;
        if (!secureDoc) {
          throw new Error('Could not access iframe document');
        }
        
        // Write content
        secureDoc.open();
        secureDoc.write(htmlContent);
        secureDoc.close();
        
        // Add script to handle unwanted text
        setTimeout(() => {
          // Add CSS to style the content and hide unwanted text
          const styleElement = document.createElement('style');
          styleElement.textContent = `
            /* Style for content wrapper to ensure proper width */
            .content-wrapper {
              max-width: 700px !important;
              margin: 0 auto !important;
              padding: 20px !important;
            }
            
            /* Hide any unwanted text that appears directly in the body */
            body > :not(script):not(style) {
              display: none !important;
            }
            
            /* But show the content wrapper */
            body > .content-wrapper {
              display: block !important;
            }
          `;
          
          // Add the style to the document
          secureDoc.head.appendChild(styleElement);
          
          // Find all headings in the document
          const headings = secureDoc.querySelectorAll('h1, h2, h3, h4, h5, h6');
          
          // If we found headings, the first one is our starting point for real content
          if (headings.length > 0) {
            const firstHeading = headings[0];
            let currentNode = firstHeading;
            
            // Create a content wrapper
            const contentWrapper = secureDoc.createElement('div');
            contentWrapper.className = 'content-wrapper';
            
            // Collect all the nodes after the first heading
            const nodesToMove = [];
            while (currentNode) {
              const nextNode = currentNode.nextSibling;
              nodesToMove.push(currentNode);
              currentNode = nextNode;
            }
            
            // Add the wrapper to the body
            secureDoc.body.appendChild(contentWrapper);
            
            // Move all the nodes to the wrapper
            nodesToMove.forEach(node => {
              contentWrapper.appendChild(node);
            });
          }
        }, 0);
      } catch (error) {
        console.error('Error writing to iframe:', error);
        container.remove();
        throw error;
      }
    };

    // 7. Set up load handler BEFORE adding to DOM
    frame.onload = writeContent;

    // 8. Build DOM structure
    container.appendChild(frame);
    container.appendChild(closeButton);
    container.appendChild(exportButton);
    
    // 9. Add to document
    document.body.appendChild(container);

    // 10. Add keyboard listener
    document.addEventListener('keydown', handleKeyDown);

    return {
      viewId,
      cleanup: () => {
        document.removeEventListener('keydown', handleKeyDown);
        container.remove();
        this.logViewerClose(viewId);
      }
    };
  }

  static logViewerOpen(viewId) {
    console.log(`Secure viewer opened: ${viewId}`);
  }

  static logViewerClose(viewId) {
    console.log(`Secure viewer closed: ${viewId}`);
  }
}